// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@hotwired/turbo-rails';
import 'controllers';

require('jquery');
require('jquery-ui');
require('jquery-ujs');

require('packs/jquery/jewelryModals');
require('packs/jquery/core_functions');
require('packs/jquery/loader');
require('packs/jquery/multipart-form');
require('packs/jquery/scripts');
