let ready = function () {
  // function
  let getCaret = function (el) {
    if (el.selectionStart) {
      return el.selectionStart;
    } else if (document.selection) {
      el.focus();
      
      let r = document.selection.createRange();
      if (r == null) {
        return 0;
      }
      
      let re = el.createTextRange(), rc = re.duplicate();
      re.moveToBookmark(r.getBookmark());
      rc.setEndPoint('EndToStart', re);
      
      return rc.text.length;
    }
    return 0;
  }
  
  // events
  $(document)
    // опросы
    .on('click', '.js--poll_prev', function (e) {
      e.preventDefault();
      let $t = $(this), $mc = $t.closest('.modal__content');
      $mc.addClass('d_none').prev('.modal__content').removeClass('d_none');
    })
    .on('click', '.js--poll_next', function (e) {
      e.preventDefault();
      let $t = $(this), $mc = $t.closest('.modal__content'), $qv = $mc.find('.question__variants'), need = $qv.length;
      $qv.each(function () {
        let $t = $(this);
        if ($t.find('input:checked').length || $t.find('textarea').length) {
          need--;
        }
      });
      
      if (need < 1) {
        $mc.addClass('d_none').next('.modal__content').removeClass('d_none');
      }
    })
    .on('click', '.js--poll_finish', function (e) {
      e.preventDefault();
      let $t = $(this), $mc = $t.closest('.modal__content'), $qv = $mc.find('.question__variants'), need = $qv.length;
      $qv.each(function () {
        let $t = $(this);
        if ($t.find('input:checked').length || $t.find('textarea').length) {
          need--;
        }
      });
      
      if (need < 1) {
        $t.closest('form').trigger('submit');
      }
    })
    // экзамен
    .on('click', '.questions .question input, .questions .question textarea', function (e) {
      let $t = $(this), $q = $t.closest('.question'),
        $form = $t.closest('form'),
        checked = ($q.find('input:checked').length > 0),
        textarea = ($q.find('textarea').length > 0),
        $numbers = $('.numbers .number'),
        num = $q.data('number'),
        $number = $('.numbers .number[data-number=' + num + ']');
      
      if (checked || textarea) {
        $number.addClass('active');
      } else {
        $number.removeClass('active');
      }
      
      if ($numbers.length === $('.numbers .number.active').length) {
        $('.js--exam_finish').removeClass('d_none');
      } else {
        $('.js--exam_finish').addClass('d_none');
      }
      
      localStorage.setItem('_savedex_', JSON.stringify($form.serializeArray()));
    })
    .on('click', '.js--exam_finish', function (e) {
      e.preventDefault();
      
      let $t = $(this), checked = true;
      $('.question:visible').each(function () {
        checked &&= ($(this).find('input:checked').length > 0);
      });
      if (checked) {
        $t.closest('form').trigger('submit');
      }
    })
    .on('click', '.js--exam_try_more', function (e) {
      e.preventDefault();
      location.reload();
    })
    .on('click', '.numbers .number', function (e) {
      let $t = $(this), num = $t.data('number'), $q = $('.questions .question[data-number=' + num + ']');
      $q.get(0).scrollIntoView({behavior: 'smooth'});
    })
    // прикрепить файл
    .on('change', '.app-file-input input[type=file]', function (e) {
      let $label = $(this).closest('label'), $svg = $label.find('svg:first'), $span = $label.find('span:first');
      
      if (!$span.data('o-value')) {
        $span.data('o-value', $span.text());
      }
      
      if (this.files[0]) {
        $svg.hide();
        $span.html(this.files[0].name);
      } else {
        $svg.show();
        $span.html($span.data('o-value'));
      }
    })
    // webinar-сообщения
    .on('keyup', '.chat form textarea', function (e) {
      if ((e.keyCode == 13 || e.keyCode == 10) && e.ctrlKey) {
        let content = $(this).val(), caret = getCaret(this);
        $(this).val(content.substring(0, caret) + "\n" + content.substring(caret, content.length));
        e.stopPropagation();
      } else if (e.keyCode == 13 || e.keyCode == 10) {
        $(this.form).find('input[type=submit]').trigger('click');
      }
    });
  
  // сохранённые ответы из экзамена
  (function () {
    let saved = localStorage.getItem('_savedex_');
    if (saved === null) {
      return false;
    }
    try {
      let ar = JSON.parse(saved), i = 0, len = ar.length;
      for (i = 0, i < len; i < len; i++) {
        let $input = $('input[name="' + ar[i].name + '"][value="' + ar[i].value + '"]');
        if ($input.length) {
          $input.trigger('click');
        }
      }
    } catch (e) {
      console.log(e);
    }
  })();
};

$(document).ready(ready);
$(document).on('page:load', ready);
